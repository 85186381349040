export const environment = {
  version: 'development',
  buildDate: '',
  environment: 'local',
  release: 'local',
  production: false,
  debug: true,
  baseUrl: 'http://localhost:4200',
  apiUrl: 'http://localhost:3000',
  apiUrlFallback: 'https://develop.api.catch-talents.dev',
  companyBaseUrl: 'http://localhost:4200',
  jobboardBaseUrl: 'http://localhost:4201',
};
